import _ from 'lodash';
import createAsyncActions from '../../../helpers/create-async-actions';
import { getChannelById } from '@wix/wix-vod-shared/dist/src/common/selectors/channels';
import { isVODChannel } from '../../../../utils/channel-helpers';
import { normalizeArrayOfVideos } from '../../../helpers/normalized';
import { getPublic as getPublicChannel } from '../../../../api/public/channel/channel';
import { getHydratedData } from '../../../hydrated-data/hydrated-data';
import { SortOrder } from '@wix/wix-vod-gql-api/dist/src/public/channel/types';
import { isGraphAPIEnabled } from '@wix/wix-vod-shared/dist/src/common/utils/experiments';
import { publicApiV3 } from '../../../../api/v3/public';
import { getPublicApi } from '../../../../api/public';

const ACTIONS = createAsyncActions('SERVER.CHANNEL.VIDEOS.LIST');
export const NAMES = ACTIONS.NAMES;
const { START, SUCCESS, FAIL } = ACTIONS;

export const defaultParams = {
  sort: {
    order: 'custom',
    direction: 'asc',
  },
  paging: {
    size: 1000,
  },
};

export const listPublicChannelVideosGql = (channelId, options = {}) => {
  _.defaultsDeep(options, defaultParams);

  return async (dispatch, getState) => {
    const params = { id: channelId, options };
    const state = getState();

    dispatch(START(params));

    const { channelVideos: hydratedChannelVideos } = getHydratedData(state);

    try {
      let response = await (hydratedChannelVideos?.data ||
        publicApiV3.channel.getChannelVideos({
          channelId,
          videosCount: options.paging.size,
          cursor: options.paging.cursor,
          sort: SortOrder.CUSTOM_DESC,
          query: options.query,
          tag: options.tag,
          category: options.category,
        }));

      response = {
        paging: { cursor: response.cursor, size: options.paging.size },
        sort: SortOrder.CUSTOM_ASC,
        data: normalizeArrayOfVideos(response.items),
      };

      dispatch(SUCCESS(params, response));
      return response;
    } catch (error) {
      dispatch(
        FAIL(params, error, {
          analytics: { type: 'error', name: 'shared.channel.videos.list' },
        }),
      );
    }
  };
};

export const listPublicChannelVideos = (channelId, options = {}) => {
  _.defaultsDeep(options, defaultParams);

  return async (dispatch, getState) => {
    const state = getState();

    if (isGraphAPIEnabled()) {
      return dispatch(listPublicChannelVideosGql(channelId, options));
    }

    const params = { id: channelId, options };
    const {
      channel: hydratedChannel,
      channelVideos: hydratedChannelVideos,
      templateMetaSiteId,
    } = getHydratedData(state);

    dispatch(START(params));

    let response;
    const publicApi = getPublicApi();

    const channel = hydratedChannel
      ? (await getPublicChannel(channelId, { hydratedChannel })).data
      : getChannelById(state, channelId);

    try {
      if (channel) {
        response = isVODChannel(channel)
          ? await publicApi.channelVideos.listChannelVideos({
              ...options,
              channelId: channel.id,
              hydratedChannelVideos,
              templateMetaSiteId,
            })
          : await publicApi.youtubeVideosService.listYoutubeChannelVideos(
              channel,
              options,
              hydratedChannelVideos,
            );
      } else {
        response = await publicApi.channelVideos.listChannelVideos({
          ...options,
          channelId,
          templateMetaSiteId,
        });

        if (!response.data.length) {
          const channelResponse = await getPublicChannel(channelId, {
            hydratedChannel,
          });

          if (channelResponse.data.externalId) {
            response =
              await publicApi.youtubeVideosService.listYoutubeChannelVideos(
                channelResponse.data,
                options,
                hydratedChannelVideos,
              );
          }
        }
      }

      response = {
        paging: response.paging,
        sort: response.sort,
        data: normalizeArrayOfVideos(response.data),
      };

      dispatch(SUCCESS(params, response));
      return response;
    } catch (error) {
      dispatch(
        FAIL(params, error, {
          analytics: { type: 'error', name: 'shared.channel.videos.list' },
        }),
      );
    }
  };
};
