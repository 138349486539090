import _ from 'lodash';
import createAsyncActions from '../../helpers/create-async-actions';
import {
  normalizeArrayOfPaymentInfoVideos,
  normalizePaymentInfoChannel,
} from '../../helpers/normalized';
import { getMemberInfo } from '../../../api/public/channel/channel';
import { getHydratedData } from '../../hydrated-data/hydrated-data';
import { createAsyncProxy } from '../../../worker/lib';
import { getCurrentSiteUser } from '../../../selectors/current-site-user';
import { isPricingPlanEnabled } from '@wix/wix-vod-shared/dist/src/common/ui-selectors/channel/pricing';
import { getBaseUrl } from '../../../selectors/api';

export const CHANNEL_PAYMENT_ACTIONS = createAsyncActions(
  'SERVER.CHANNEL.PAYMENT',
);

export const getPaymentInfo = createAsyncProxy(
  'payment.getPaymentInfo',
  function (id) {
    return async function (dispatch, getState, { handlers }) {
      const state = getState();
      const baseUrl = getBaseUrl(state);
      const isPricingPlan = isPricingPlanEnabled({
        dealInfo:
          // TODO: fix this ridiculous elvis chain
          state.hydratedData.data?.channel?.data?.deal_info ||
          state.channelInfo.info.dealInfo,
      });

      const currentSiteUser = getCurrentSiteUser(state);
      const { instance, memberInfo: hydratedMemberInfo } =
        getHydratedData(state);

      if (!(currentSiteUser && currentSiteUser.id)) {
        return Promise.resolve().then(() =>
          dispatch(CHANNEL_PAYMENT_ACTIONS.SUCCESS()),
        );
      }

      if (isPricingPlan) {
        const channelId = state.currentChannelId.currentChannelId;

        handlers.requestPricingPlanPaymentInfo(channelId, currentSiteUser.id);
      }

      const {
        channelVideos: { ids: items },
      } = state;
      const options = {
        instance,
        items,
      };

      const params = { id, options };
      dispatch(CHANNEL_PAYMENT_ACTIONS.START(params));

      return getMemberInfo(baseUrl, id, instance, {
        hydratedMemberInfo,
      }).then(
        function (response) {
          const items = _.get(response, 'data.items', []);

          if (!items.length) {
            dispatch(CHANNEL_PAYMENT_ACTIONS.SUCCESS(params, response));
            return response;
          }

          const [channelData, videoDataList] = _.partition(
            items,
            (item) => !item.itemId,
          );

          const channel = _.get(channelData, '[0]');
          const videos = _.map(videoDataList, (video) =>
            _.pick(video, 'dgsInfo', 'itemId', 'listId'),
          );

          let normalizeChannel = {};

          if (channel) {
            normalizeChannel = {
              ...normalizePaymentInfoChannel(
                _.pick(channel, 'dgsInfo', 'listId'),
              ),
            };
          }

          /**
           * We are getting channel and video ids from backend in form 75597969-d0e0fda03fb64c7bbed8746046bff110,
           * where 75597969 - publish id, d0e0fda03fb64c7bbed8746046bff110 - item id.
           * This id is stored in itemId property.
           * But when we receive dgsInfo from member info - we have simple id in above mentioned
           * property.
           * So we extracting only dgsInfo, dropping itemId.
           */

          const { result, entities } =
            normalizeArrayOfPaymentInfoVideos(videos);

          const dgsInfo = _.reduce(
            entities.videos,
            (acc, { dgsInfo }, key) => ({
              ...acc,
              [key]: { dgsInfo },
            }),
            {},
          );

          response = {
            ...response,
            data: _.merge({}, normalizeChannel, {
              result,
              entities: {
                ...entities,
                videos: dgsInfo,
              },
            }),
          };

          dispatch(CHANNEL_PAYMENT_ACTIONS.SUCCESS(params, response));
          return response;
        },

        function (reason) {
          const meta = {
            analytics: { type: 'error', name: 'shared.channel.payment.get' },
          };

          if (_.isError(reason)) {
            dispatch(CHANNEL_PAYMENT_ACTIONS.FAIL(reason, null, meta));
          } else {
            dispatch(CHANNEL_PAYMENT_ACTIONS.FAIL(params, reason, meta));
          }

          return Promise.reject(reason);
        },
      );
    };
  },
);
