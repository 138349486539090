import createAsyncActions from '../../helpers/create-async-actions';
import { normalizeChannel } from '../../helpers/normalized';
import { getPublic as getChannelPublic } from '../../../api/public/channel/channel';
import { getHydratedData } from '../../hydrated-data/hydrated-data';
import { createAsyncProxy } from '../../../worker/lib';
import { isGraphAPIEnabled } from '@wix/wix-vod-shared/dist/src/common/utils/experiments';
import { publicApiV3 } from '../../../api/v3/public';

const ACTIONS = createAsyncActions('SERVER.CHANNEL.GET');
export const NAMES = ACTIONS.NAMES;
const { START, SUCCESS, FAIL } = ACTIONS;

export const getPublicChannel = createAsyncProxy(
  'channel.getPublic',
  (id) => (dispatch, getState) => {
    const params = { id };
    const state = getState();
    const { channel: hydratedChannel, templateMetaSiteId } =
      getHydratedData(state);
    dispatch(START(params));

    const promise = isGraphAPIEnabled()
      ? hydratedChannel
        ? Promise.resolve(hydratedChannel)
        : publicApiV3.channel.getChannel(id)
      : getChannelPublic(id, {
          hydratedChannel,
          templateMetaSiteId,
        });

    return promise.then(
      function (response) {
        const { data = {} } = response;

        response = {
          ...response,
          data: normalizeChannel(data),
        };

        dispatch(SUCCESS(params, response));
        return response;
      },

      function (response) {
        dispatch(
          FAIL(params, response, {
            analytics: { type: 'error', name: 'shared.channel.video.get' },
          }),
        );
      },
    );
  },
);
